import * as React from "react"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Link } from "gatsby"
import {Col, Container, Row} from 'react-bootstrap'
import {Trans} from 'gatsby-plugin-react-i18next';

import MapIcon from '../../static/map.png';
import ArrowRight from '../../static/arrow-left.png';
import BoltSolid from '../../static/bolt-solid.png';

const Project = ({ data }) => {
  const project = data.allContentfulProject.edges[0].node
  return (
    <Layout title={"Varmaorka - " + project.name}>
      <div className="py-offset">
        <Container>
          <Row className="align-items-center mb-2">
            <Col md={12}>
              <h2 className="section-title section-title-inner">{project.name}</h2>
            </Col>
          </Row>
          <div className="text-end post-meta mb-4">
            <p>{project.startedText}</p>
            <p>{project.finishedText}</p>
            <p><img src={MapIcon} alt="map icon" /> {project.locationLatlong}</p>
          </div>

          <div className="mb-5 post-image">
            <GatsbyImage image={project.image.gatsbyImageData} alt={project.image.description} />
            <div className="post-image-overlay"><img src={BoltSolid} alt="map icon" /> {project.capacity}</div>
          </div>

          <div className="post-content">{renderRichText(project.content)}</div>

          <div className="text-end pt-md-5 pt-4">
            <Link to={'/projects'} activeClassName="active" className="btn-link"><Trans>All Projects</Trans><img src={ArrowRight} alt="arrow-left" /></Link>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
query ProjectsBySlug(
    $slug: String!,
    $language: String!
) {
  allContentfulProject (filter: {slug: {eq: $slug}, node_locale: {eq: $language}}) {
    edges {
      node {
        slug
        name
        locationLatlong
        startedText
        finishedText
        capacity
        image {
          gatsbyImageData
        }
        order
        content {
          raw
        }
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}`

export default Project
